<template>
	<section class="relative min-h-full" id="mapContainer">
		<modal v-on:closeModal="closeModal" v-if="showModal"></modal>
		<search v-on:closeSearch="closeSearch" v-on:setSearchString="setSearchString" v-if="showSearch" v-bind:search-string-current="searchString"></search>
		<div class="absolute top-0 left-0 z-[9999] max-h-full min-w-96 py-5 flex flex-row-reverse"
			 :style="teaserPaneStyles"
			 v-if="showTeaserPanel">
			<button id="closeTeaserPanel" class="absolute top-5 -right-16 mt-0" :class="toggleClasses"
					v-on:click="showTeaserPanelToggle = !showTeaserPanelToggle">
				<img :src="getIconPath( 'chevron-left', 'blue' )"
					 :alt="$t('string.show_teasers')">
			</button>
			<div
				class="h-full max-w-md overflow-y-scroll scrollbar-thin scrollbar-thumb-pax_white scrollbar-thumb-rounded px-5 pr-3 mr-2">
				<track-teaser
					v-for="track in tracks"
					v-bind:key="track.id"
					v-model="track.visible"
					v-bind:track="track"
					v-if="showTracks"
					v-on:focusTrack="centerOnTrack"
					v-on:unfocusTrack="unsetFocusTrack"
				></track-teaser>
				<marker-teaser
					v-for="marker in markers"
					v-bind:key="marker.id"
					v-model="marker.visible"
					v-bind:marker="marker"
					v-if="showMarkers"
					v-on:focusMarker="centerOnMarker"
				></marker-teaser>
				<event-teaser
					v-for="event in events"
					v-bind:key="event.id"
					v-model="event.visible"
					v-bind:event="event"
					v-if="showEvents"
					v-on:focusEvent="centerOnEvent"
				></event-teaser>
			</div>
		</div>
		<l-map
			ref="paxwalk_map"
			style="height: 70vh"
			:zoom="zoom"
			:center="center"
			:options="mapOptions"
			@update:zoom="zoomUpdated"
			@update:center="centerUpdated"
			@update:bounds="boundsUpdated"
			@ready="onMapReady"
		>
			<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
			<l-control position="topleft" ref="teaser_panel">
				<button :class="toggleClasses"
						v-show="!showTeaserPanel"
						v-on:click="showTeaserPanelToggle = !showTeaserPanelToggle"
						:title="$t('string.show_teasers')"
				>
					<img :src="getIconPath( 'chevron-right', 'blue' )">
				</button>
			</l-control>
			<l-control position="bottomright">
				<button :class="showModalClasses"
						v-show="!showModal"
						v-on:click="openModal"
						:title="$t('string.show_modal')"
				>
					?
				</button>
			</l-control>
			<l-control-zoom position="bottomright"></l-control-zoom>
			<l-control ref="map_filters" position="topright">
				<section class="flex justify-end gap-2">
					<div class="flex justify-end" v-show="!showSearch">
						<button :class="searchToggleClasses"
								v-on:click="showSearch = !showSearch">
							<img :src="getIconPath( 'search', (searchString.length) ? 'white' : 'blue' )"
								 :alt="$t('string.show_search')">
						</button>
					</div>
					<div class="flex justify-end" v-show="showTracks">
						<div
							class="route-filter w-full absolute right-136 flex flex-wrap 3xl:flex-nowrap justify-between gap-4 content-center items-center p-4 bg-pax_white text-pax_gray-500"
							v-if="showTrackFilters">
							<options-filter v-bind:options="trackDifficulties"
											v-bind:label="$t('string.track_difficulty')"
											v-bind:className="'difficulty-filter'"></options-filter>
							<options-filter v-bind:options="trackSignages" v-bind:label="$t('string.track_signage')"
											v.bind:className="'signage-filter'"></options-filter>
						</div>
						<button :class="filterToggleClasses"
								v-on:click="showTrackFilters = !showTrackFilters">
							<img :src="getIconPath( 'filter', (showTrackFilters) ? 'light' : 'blue' )"
								 :alt="$t('string.filters')">
							<img v-if="activeTrackFilters"
								 class="absolute w-8 h-8 p-2 -top-3 -right-3 bg-pax_blue-400 text-pax_white border border-pax_white rounded-2xl"
								 :src="getIconPath('check', 'light' )"
								 :alt="$t('string.filters_selected')"></button>
					</div>
					<div class="flex justify-end">
						<button :class="featureToggleClasses"
								v-on:click="showFeatureToggles = !showFeatureToggles">
							<img :src="getIconPath( 'layer-group', (showFeatureToggles) ? 'light' : 'blue' )"
								 :alt="$t('string.layers')">
						</button>
					</div>
				</section>
				<section class="w-25 absolute right-0 flex flex-col items-end gap-px mt-2 bg-pax_trans_blue-500"
						 v-show="showFeatureToggles">
					<feature-toggle
						v-bind:show="showTracks" v-bind:label="$t('string.tracks')"
						v-bind:icon="getIconPath( 'route', (showTracks) ? 'light' : 'dark' )"
						v-bind:message="'toggleTracks'" v-on:toggleTracks="toggleTracks"
					></feature-toggle>
					<feature-toggle
						v-bind:show="showEvents" v-bind:label="$t('string.events')"
						v-bind:icon="getIconPath( 'event', (showEvents) ? 'light' : 'dark' )"
						v-bind:message="'toggleEvents'" v-on:toggleEvents="toggleEvents"
					></feature-toggle>
					<feature-toggle
						v-bind:show="showMarkers" v-bind:label="$t('string.pilgrim_centres')"
						v-bind:icon="getIconPath( 'centre', (showMarkers) ? 'light' : 'dark' )"
						v-bind:message="'toggleMarkers'" v-on:toggleMarkers="toggleMarkers"
					></feature-toggle>
				</section>
			</l-control>
			<l-feature-group
				ref="markers"
				v-bind:visible="showMarkers"
			>
				<l-marker
					ref="marker_ref"
					v-for="marker in markers"
					v-bind:key="marker.id"
					v-if="marker.visible"
					:lat-lng="marker.coordinates"
					:name="marker.title"
				>
					<l-icon
						:icon-url="marker.icon"
						:icon-size="[32,32]"
						:icon-anchor="[16,32]"
						:shadow-url="null"
					/>
					<l-tooltip :options="tooltipOptions">
						<h3 v-html="marker.title"></h3>
					</l-tooltip>
					<l-popup :options="popupOptions">
						<marker-popup
							v-model="marker.visible"
							v-bind:marker="marker"
						></marker-popup>
					</l-popup>
				</l-marker>
			</l-feature-group>

			<l-feature-group
				ref="tracks"
				v-bind:visible="showTracks"
			>
				<l-gpx
					ref="track_ref"
					v-for="track in tracks"
					v-bind:key="track.id"
					:gpx-file="track.file"
					:gpx-options="track.options"
					:visible="track.visible"
					@gpx-loaded="onTrackLoaded"
				>
					<l-tooltip>
						<h3 v-html="track.title"></h3>
					</l-tooltip>
					<l-popup :options="trackPopupOptions">
						<track-popup
							v-model="track.visible"
							v-bind:track="track"
							v-bind:difficulties="trackDifficulties"
						></track-popup>
					</l-popup>
				</l-gpx>
			</l-feature-group>

			<l-feature-group
				ref="events"
				v-bind:visible="showEvents"
			>
				<marker-cluster>
					<l-marker
						ref="event_ref"
						v-for="event in events"
						v-bind:key="event.id"
						:lat-lng="event.coordinates"
						:name="event.title"
					>
						<l-icon
							:icon-url="event.icon"
							:icon-size="[32,32]"
							:icon-anchor="[16,32]"
							:shadow-url="null"
						/>
						<l-tooltip :options="tooltipOptions">
							<h3 v-html="event.title"></h3>
						</l-tooltip>
						<l-popup :options="popupOptions">
							<event-popup
								v-model="event.visible"
								v-bind:event="event"
							></event-popup>
						</l-popup>
					</l-marker>
				</marker-cluster>
			</l-feature-group>
		</l-map>
	</section>
</template>

<script>
import Vue from 'vue';
import L from 'leaflet';
import Axios from "axios";
import {
	LMap, LTileLayer, LControl, LControlZoom, LMarker, LIcon, LTooltip, LPopup, LFeatureGroup
} from 'vue2-leaflet';
import LGpx from 'vue2-leaflet-gpx';
import 'leaflet/dist/leaflet.css';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import OptionsFilter from "./filter";
import FeatureToggle from "./feature_toggle";
import TrackTeaser from "./track_teaser";
import MarkerTeaser from "./marker_teaser";
import EventTeaser from "./event_teaser";
import TrackPopup from "./track_popup";
import MarkerPopup from "./marker_popup";
import EventPopup from "./event_popup";
import Modal from "./modal";
import Search from "./search";

export default {
	name: "paxwalk-map",
	components: {
		LMap, LTileLayer, LControl, LControlZoom, LMarker, LIcon, LTooltip, LPopup, LFeatureGroup, LGpx,
		'marker-cluster': Vue2LeafletMarkerCluster,
		OptionsFilter, FeatureToggle, TrackTeaser, MarkerTeaser, EventTeaser, TrackPopup, MarkerPopup, EventPopup, Modal, Search
	},
	data() {
		return {
			mapRef: null,
			teaserPaneRef: null,
			url: 'https://maps.geoapify.com/v1/tile/positron/{z}/{x}/{y}.png?&apiKey=6a8623c4116c4388abe6479128dfb500',
			attribution:
				'<a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | &copy; <a href="https://openmaptiles.org/" rel="nofollow" target="_blank">OpenMapTiles</a>, <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors',
			zoom: 5,
			center: [61.85614879566797, 13.855501620626757],
			outerBounds: [
				L.latLng(71.37812702610609, -24.367675781250004),
				L.latLng(49.96535590991311, 38.95751953125001),
			],
			searchString: this.searchString,
			markers: this.markers,
			tracks: this.tracks,
			trackDifficulties: this.trackDifficulties,
			trackSignages: this.trackSignages,
			events: this.events,
			focused: false,
			showTracks: true,
			showTrackFilters: false,
			showFeatureToggles: false,
			showMarkers: true,
			showEvents: true,
			showModal: true,
			showSearch: false,
			showTeaserPanelToggle: false,
			bounds: null,
			language: 'en',
		};
	},
	created() {
		this.mapRef = null;
		this.teaserPaneRef = null;
		this.markers = [];
		this.tracks = [];
		this.trackDifficulties = [];
		this.trackSignages = [];
		this.events = [];
		this.focused = false;
		this.showModal = !this.getModalCookie();
		this.showSearch = false;
		this.showTracks = true;
		this.showTrackFilters = false;
		this.showFeatureToggles = false;
		this.showTeaserPanelToggle = (this.$windowWidth > 1024);
		this.showMarkers = true;
		this.showEvents = true;
		this.searchString = this.searchString || "";
		this.bounds = null;
		this.language = window.paxwalk_map.current_lang;
	},
	mounted() {
		Axios.get("/wp-json/wp/v2/route?paxwalk_per_page=200&orderby=title&order=asc&lang=" + this.language).then((response) =>
			this.parseRoutes(response.data)
		);

		Axios.get("/wp-json/wp/v2/pilgrim-centre?paxwalk_per_page=200&orderby=title&order=asc&lang=" + this.language).then((response) =>
			this.parsePilgrimCentres(response.data)
		);

		Axios.get("/wp-json/wp/v2/route-difficulty?lang=" + this.language).then((response) =>
			this.parseRouteDifficulties(response.data)
		);

		Axios.get("/wp-json/wp/v2/route-signage?lang=" + this.language).then((response) =>
			this.parseRouteSignages(response.data)
		);

		Axios.get("/wp-json/wp/v2/event?per_page=50&lang=" + this.language).then((response) =>
			this.parseEvents(response.data)
		);
	},
	computed: {
		mapOptions: function () {
			return {
				scrollWheelZoom: false,
				zoomControl: false,
				zoomSnap: 1,
				zoomDelta: 1,
				maxBounds: this.outerBounds,
				minZoom: 5,
			}
		},
		enabledDifficultyIDs: function () {
			let IDs = [];
			this.trackDifficulties.forEach(
				(option, ID) => {
					if (true === option.enabled) {
						IDs.push(option.id);
					}
				}
			);

			return IDs;
		},
		enabledSignageIDs: function () {
			let IDs = [];
			this.trackSignages.forEach(
				(option, ID) => {
					if (true === option.enabled) {
						IDs.push(option.id);
					}
				}
			);

			return IDs;
		},
		teaserPaneStyles: function () {
			return {
				height: this.getMapSize()['y'] + 'px',
			}
		},
		activeTrackFilters: function () {
			let active = false;
			this.trackDifficulties.forEach(
				(option, ID) => {
					if (!option.enabled) {
						active = true;
					}
				}
			);
			this.trackSignages.forEach(
				(option, ID) => {
					if (!option.enabled) {
						active = true;
					}
				}
			);
			return active;
		},
		showTeaserPanel: function () {
			return this.showTeaserPanelToggle;
		},
		showModalClasses: function () {
			return [
				'flex',
				'flex-col',
				'flex-wrap',
				'items-center',
				'justify-center',
				'w-9',
				'h-9',
				'bg-pax_white',
				'text-pax_blue-400',
				'font-bold',
				'text-lg',
				'border-2',
				'border-pax_blue-400',
				'rounded-3xl',
			];
		},
		toggleClasses: function () {
			return [
				'flex',
				'flex-col',
				'flex-wrap',
				'items-center',
				'justify-center',
				'h-16',
				'w-16',
				'bg-pax_white',
				'text-pax_blue-400',
				'border',
				'border-pax_blue-400',
			];
		},
		featureToggleClasses: function () {
			let classes = [
				'flex',
				'flex-col',
				'justify-center',
				'items-center',
				'h-16',
				'w-16',
				'border',
				'border-pax_blue-400',
			];
			if (this.showFeatureToggles) {
				classes.push('bg-pax_blue-400');
				classes.push('text-pax_white');
			} else {
				classes.push('text-pax_blue-400');
				classes.push('bg-pax_white');
			}

			return classes;
		},
		searchToggleClasses: function () {
			let classes = [
				'flex',
				'flex-col',
				'justify-center',
				'items-center',
				'h-16',
				'w-16',
				'border',
				'border-pax_blue-400',
			];
			if (this.searchString) {
				classes.push('bg-pax_blue-400');
				classes.push('text-pax_white');
			} else {
				classes.push('text-pax_blue-400');
				classes.push('bg-pax_white');
			}

			return classes;
		},
		filterToggleClasses: function () {
			let classes = [
				'relative',
				'flex',
				'flex-col',
				'justify-center',
				'items-center',
				'h-16',
				'w-16',
				'border',
				'border-pax_blue-400',
			];
			if (this.showTrackFilters) {
				classes.push('bg-pax_blue-400');
				classes.push('text-pax_white');
			} else {
				classes.push('text-pax_blue-400');
				classes.push('bg-pax_white');
			}

			return classes;
		},
		popupOptions: function () {
			return {
				'maxWidth': 720,
				'minWidth': 420,
				'maxHeight': true,
				'offset': L.point(0, -24),
			};
		},
		zoomOptions: function () {
			return {
				'padding': L.point(50, 50),
			}
		},
		startZoom: function () {
			return [61.85614879566797, 13.855501620626757];
		},
		startBounds: function() {
			return L.latLngBounds(
				L.latLng(71.37812702610609, -24.367675781250004),
				L.latLng(49.96535590991311, 38.95751953125001)
			);
		},
		trackPopupOptions: function () {
			return {
				'maxWidth': 720,
				'minWidth': 420,
				'maxHeight': true,
			};
		},
		tooltipOptions: function () {
			return {
				'offset': L.point(16, -16),
			}
		},
	},
	methods: {
		zoomUpdated(zoom) {
			this.zoom = zoom;
		},
		setSearchString(string) {
			this.searchString = string;
			this.boundsUpdated(this.startBounds);
			this.mapRef.fitBounds(this.startBounds, this.zoomOptions)
			this.zoomUpdated(5);
		},
		setFocus() {
			this.focused = true;
		},
		unsetFocusTrack() {
			this.focused = false;
			this.zoomUpdated(5);

			this.tracks.forEach(
				(track, ID) => {
					if (this.tracks[ID].focused === true) {
						this.tracks[ID].focused = false;
						this.tracks[ID].visibility = this.showTrack(track);
					}
				}
			);
		},
		unsetFocusMarker() {
			this.focused = false;

			this.markers.forEach(
				(marker, ID) => {
					if (this.markers[ID].focused === true) {
						this.markers[ID].focused = false;
					}
				}
			);
		},
		unsetFocusEvent() {
			this.focused = false;

			this.events.forEach(
				(event, ID) => {
					if (this.events[ID].focused === true) {
						this.events[ID].focused = false;
					}
				}
			);
		},
		zoomLevel() {
			if (this.zoom >= 5 && this.zoom < 8) {
				return 1;
			} else if (this.zoom >= 8 && this.zoom < 10) {
				return 2;
			}
			return 3;
		},
		showTrack(track) {
			let visible = true;

			if (this.focused) {
				return track.focused;
			} else if (this.searchString.length) {
				let stringMatch = track.title.toLowerCase().includes(this.searchString.toLowerCase());
				visible = stringMatch;

				if (stringMatch && this.bounds) {
					visible = (track.bounds) ? this.bounds.overlaps(track.bounds) : false;
				}
			} else if (!this.searchString.length){
				if (this.bounds) {
					visible = (track.bounds) ? this.bounds.overlaps(track.bounds) : false;
				}

				if (visible && track.layer > this.zoomLevel()) {
					visible = false;
				}
			}

			return visible;
		},
		showMarker(marker) {
			let visible = true;

			if (this.searchString.length) {
				let stringMatch = marker.title.toLowerCase().includes(this.searchString.toLowerCase());
				visible = stringMatch;

				if (stringMatch && this.bounds) {
					visible = (marker.coordinates) ? this.bounds.contains(marker.coordinates) : false;
				}
			} else {
				if (this.bounds) {
					visible = (marker.coordinates) ? this.bounds.contains(marker.coordinates) : false;
				}

				if (visible && marker.layer > this.zoomLevel()) {
					visible = false;
				}
			}

			return visible;
		},
		centerUpdated(center) {
			this.center = center;
		},
		boundsUpdated(bounds) {
			this.bounds = bounds;

			if (typeof this.bounds != 'undefined') {
				this.markers.forEach(
					(marker, ID) => {
						this.markers[ID].visible = this.showMarker(marker);
					}
				);

				this.tracks.forEach(
					(track, ID) => {
						this.tracks[ID].visible = this.showTrack(track);
					}
				);

				this.events.forEach(
					(event, ID) => {
						this.events[ID].visible = (event.coordinates) ? this.bounds.contains(event.coordinates) : false;
					}
				);
			}
		},
		centerOnTrack(trackId) {
			this.setFocus();

			this.tracks.forEach(
				(track, ID) => {
					if (this.tracks[ID].focused === true) {
						this.tracks[ID].focused = false;
					} else if (track.id === trackId && track.bounds) {
						this.tracks[ID].focused = true;
						this.mapRef.fitBounds(track.bounds, this.zoomOptions);
					}
				}
			);
		},
		centerOnMarker(markerId) {
			this.markers.forEach(
				(marker, ID) => {
					if (marker.id === markerId && marker.coordinates) {
						this.mapRef.setView(marker.coordinates, 12, this.zoomOptions);
					}
				}
			);
		},
		centerOnEvent(eventId) {
			this.events.forEach(
				(event, ID) => {
					if (event.id === eventId && event.coordinates) {
						this.mapRef.setView(event.coordinates, 12, this.zoomOptions);
					}
				}
			);
		},
		onMapReady() {
			this.mapRef = this.$refs.paxwalk_map.mapObject;
			this.teaserPaneRef = this.$refs.teaser_panel.mapObject;
		},
		onTrackLoaded(event) {
			this.tracks.forEach(
				(track, ID) => {
					if (track.file === event.target._gpx) {
						this.tracks[ID].bounds = event.target.getBounds();
						this.tracks[ID].visible = this.showTrack(track);
					}
				}
			);
		},
		parseRoutes(data) {
			data.forEach(
				(post, ID) => {
					let route = {
						id: post.id,
						title: post.title.rendered,
						description: post.acf.description ?? '',
						file: post.acf['gpx-track'] ? post.acf['gpx-track'].url : '',
						length: post.acf.length ? post.acf.length + ' km' : 0,
						difficulty: post.acf.difficulty_level ? {
							id: post.acf.difficulty_level.term_id,
							name: post.acf.difficulty_level.name,
							slug: post.acf.difficulty_level.slug,
							level: post.route_difficulty_level,
						} : {},
						visibility: post.acf.route_signage ? {
							id: post.acf.route_signage.term_id,
							name: post.acf.route_signage.name,
							slug: post.acf.route_signage.slug,
							level: post.route_signage_level,
						} : {},
						website: post.acf.link_to_route_page ? post.acf.link_to_route_page : '',
						image: post.acf.image ? post.acf.image.sizes['rectangular-thumbnail'] : '',
						thumbnail: post.acf.image ? post.acf.image.sizes['thumbnail'] : '',
						layer: post.acf.map_layer ? parseInt(post.acf.map_layer) : 1,
						visible: true,
						focused: false,
						bounds: null,
						options: {},
					};
					route.options = this.getTrackOptions(route);
					this.tracks.push(route);
				}
			);
		},
		parsePilgrimCentres(data) {
			data.forEach(
				(post, ID) => {
					let layer = post.acf.map_layer ? parseInt(post.acf.map_layer) : 1;

					let centre = {
						id: post.id,
						icon: post.acf.map_icon ? post.acf.map_icon.url : this.getIconPath('location-icon', ''),
						title: post.title.rendered,
						description: post.acf.short_description ? post.acf.short_description : post.content.rendered,
						coordinates: post.acf.map_location ? {
							lat: post.acf.map_location.lat,
							lng: post.acf.map_location.lng,
						} : {lat: 0, lng: 0},
						layer: layer,
						city: post.acf.city ? post.acf.city : '',
						image: post.acf.image ? post.acf.image.sizes['rectangular-thumbnail'] : '',
						thumbnail: post.thumbnail_url,
						website: post.acf.website ? post.acf.website : '',
						visible: layer <= 1,
						focused: false,
					};

					this.markers.push(centre);
				}
			);
		},
		parseRouteDifficulties(data) {
			data.forEach((term, ID) => {
					let option = {
						id: term.id,
						type: 'difficulty',
						name: term.name,
						slug: term.slug,
						level: (term.acf.difficulty_level) ?? 'moderate',
						enabled: true,
					};

					this.trackDifficulties.push(option);
				}
			);
		},
		parseRouteSignages(data) {
			data.forEach((term, ID) => {
					let option = {
						id: term.id,
						type: 'signage',
						name: term.name,
						slug: term.slug,
						signage: (term.acf.signage_level) ?? 'no_signage',
						enabled: true,
					};

					this.trackSignages.push(option);
				}
			);
		},
		parseEvents(data) {
			data.forEach((post, ID) => {
				let event = {
					visible: true,
					focused: false,
					id: post.id,
					title: post.title.rendered,
					description: post.excerpt.rendered,
					content: post.content.rendered,
					coordinates: (post.venue_lat && post.venue_lon) ? {
						lat: post.venue_lat,
						lng: post.venue_lon
					} : {lat: 0, lng: 0},
					startDate: (post.event_start_date) ? new Date(post.event_start_date) : null,
					endDate: (post.event_end_date) ? new Date(post.event_end_date) : null,
					link: post.link,
					externalLink: post.ife_event_link,
					organizerName: post.organizer_name,
					venueName: post.venue_name,
					imageUrl: post.image_url,
					thumbnailUrl: post.thumbnail_url,
					icon: this.getIconPath('event-icon', ''),
				};

				this.events.push(event);
			});
		},
		getTrackOptions(track) {
			let line_color = {1: '#DB520D', 2: '#FFD200', 3: '#35D046'};
			let line_weight = {1: 4, 2: 3, 3: 2};
			let line_opacity = {1: 1, 2: 0.75, 3: 0.75};

			let defaultOptions = {
				async: true,
				gpx_options: {
					parseElements: ['track']
				},
				marker_options: {
					startIconUrl: null,
					endIconUrl: null,
					iconUrl: null,
					shadowUrl: null,
					wptIconUrls: {
						'': '/app/plugins/paxwalk-map/images/icons/pin-icon-wpt.png',
					}
				},
				polyline_options: {
					color: (track.layer) ? line_color[track.layer] : 'orange',
					opacity: (track.layer) ? line_opacity[track.layer] : 0.75,
					weight: (track.layer) ? line_weight[track.layer] : 3,
					dashArray: "4 1",
					lineCap: 'round',
					stroke: true,
					fill: false,
				}
			};

			return Object.assign(defaultOptions, track.options);
		},
		getMapSize() {
			return (this.mapRef) ? this.mapRef._size : [0, 0];
		},
		getModalCookie() {
			return (this.$cookies.get('mapModalClosed')) ? true : false;
		},
		getIconPath(icon, variation = 'blue') {
			if (variation) {
				icon += '_' + variation;
			}
			return '/app/plugins/paxwalk-map/src/icons/' + icon + '.svg';
		},
		toggleFilterOption(selectedOption) {
			if (selectedOption.type === 'difficulty') {
				this.trackDifficulties.forEach(
					(option, ID) => {
						if (option.level === selectedOption.level) {
							option.enabled = !option.enabled;
						}
					}
				);
			} else {
				this.trackSignages.forEach(
					(option, ID) => {
						if (option.signage === selectedOption.signage) {
							option.enabled = !option.enabled;
						}
					}
				);
			}
		},
		toggleTracks() {
			this.showTracks = !this.showTracks;
		},
		closeModal() {
			this.$cookies.set('mapModalClosed', true);
			this.showModal = false;
		},
		closeSearch() {
			this.showSearch = false;
		},
		openModal() {
			this.showModal = true;
		},
		openSearch() {
			this.showSearch = true;
		},
		toggleMarkers() {
			this.showMarkers = !this.showMarkers;
		},
		toggleEvents() {
			this.showEvents = !this.showEvents;
		},
		updateTrackVisibility(option) {
			this.toggleFilterOption(option);
			let enabledSignageOptions = this.enabledSignageIDs;
			let enabledDifficultyOptions = this.enabledDifficultyIDs;

			this.tracks.forEach(
				(track, ID) => {
					let visible = true;

					if (
						enabledDifficultyOptions.length === this.trackDifficulties.length &&
						enabledSignageOptions.length === this.trackSignages.length
					) {
						visible = true;
					} else {
						visible = enabledDifficultyOptions.includes(track.difficulty.id) &&
							enabledSignageOptions.includes(track.visibility.id);
					}

					// If still visible, apply map layer and bounds filter.
					if (visible) {
						visible = this.showTrack(track);
					}

					track.visible = visible;
				}
			);
		}
	}
}
</script>

<style scoped>

</style>
