<template>
	<article v-if="track.visible" class="min-w-416 bg-pax_white mb-5">
		<img v-if="track.image" :src="track.image" :alt="track.title">
		<div class="prose p-3">
			<header>
				<a v-if="track.website" :href="track.website" target="_blank">
					<h3 v-html="track.title" class="m-0 text-pax_gray-800"></h3>
				</a>
				<h3 v-if="!track.website" v-html="track.title" class="m-0 text-pax_gray-800"></h3>
			</header>
			<ul class="p-0 my-1 mb-3">
				<li v-if="isTerm(track.difficulty)" class="inline-block p-0 m-0 mr-1">
					<term-label :term="track.difficulty"></term-label>
				</li>
				<li v-if="isTerm(track.visibility)" class="inline-block p-0 m-0 mr-1">
					<term-label :term="track.visibility"></term-label>
				</li>
				<li v-if="track.length" class="inline-block p-0 m-0">
					<icon-label :icon="'before:bg-arrows-left-right-dark'" :label="track.length"></icon-label>
				</li>
			</ul>
			<p v-if="track.description" v-html="track.description" class="text-sm text-pax_gray-450"></p>
			<footer class="pt-2">
				<button v-if="!track.focused" v-on:click="setFocus" :class="buttonClasses">
					<icon-label :icon="'after:bg-arrow-right-blue'" :label="$t('string.show_on_map')" :position="'after'"></icon-label>
				</button>
				<button v-if="track.focused" v-on:click="unsetFocus" :class="buttonClassesInverted">
					<icon-label :icon="'before:bg-arrow-left-light'" :label="$t('string.show_all_routes')"></icon-label>
				</button>
			</footer>
		</div>
	</article>
</template>

<script>
import TermLabel from "./term-label";
import IconLabel from "./icon-label";

export default {
	name: "TrackTeaser",
	components: { TermLabel, IconLabel },
	props: {
		track: Object
	},
	computed: {
		buttonClasses:function() {
			return [
				'cursor-zoom-in',
				'border',
				'border-pax_trans_gray-500',
				'bg-pax_white',
				'p-3',
				'py-2',
				'text-pax_blue-400',
			];
		},
		buttonClassesInverted:function() {
			return [
				'cursor-zoom-out',
				'border',
				'border-pax_trans_gray-500',
				'bg-pax_blue-500',
				'p-3',
				'py-2',
				'text-pax_white',
			];
		}
	},
	methods: {
		setFocus () {
			this.$emit( 'focusTrack', this.track.id );
		},
		unsetFocus () {
			this.$emit( 'unfocusTrack' );
		},
		isTerm ( term ) {
			return typeof term === 'object';
		}
	}
}
</script>

<style scoped>

</style>
