export default {
	en: {
		string: {
			track: 'Track',
			tracks: 'Tracks',
			track_difficulty: 'Difficulty',
			track_signage: 'Signage',
			event: 'Event',
			events: 'Events',
			pilgrim_centre: 'Pilgrim Centre',
			pilgrim_centres: 'Pilgrim Centres',
			filter: 'Filter',
			filters: 'Filters',
			filters_selected: 'Filters selected',
			layers: 'Layers',
			show_teasers: 'Show previews',
			hide_teasers: 'Hide previews',
			show_on_map: 'Show on map',
			show_all_routes: 'Show all routes',
			show_modal: 'Show help',
			map_modal_title: 'Welcome',
			map_modal_text: 'There are a great number of beautiful routes and to make things more legible, we have divided them into three levels. Zoom into the map to reveal more routes.',
			map_modal_button_text: 'OK, got it!',
			show_search: 'Search',
			search_modal_title: 'Search',
			search_modal_text: 'Find routes and pilgrim centres by name.',
			search_modal_search_button_text: 'Search',
			search_modal_cancel_button_text: 'Cancel',
		},
		month: {
			0: 'Jan',
			1: 'Feb',
			2: 'Mar',
			3: 'Apr',
			4: 'May',
			5: 'Jun',
			6: 'Jul',
			7: 'Aug',
			8: 'Sep',
			9: 'Oct',
			10: 'Nov',
			11: 'Dec',
		}
	},
	sv: {
		string: {
			track: 'Led',
			tracks: 'Leder',
			track_difficulty: 'Svårighet',
			track_signage: 'Skyltning',
			event: 'Evenemang',
			events: 'Evenemang',
			pilgrim_centre: 'Pilgrimscenter',
			pilgrim_centres: 'Pilgrimscentra',
			filter: 'Filter',
			filters: 'Filter',
			filters_selected: 'Filter valda',
			layers: 'Lager',
			show_teasers: 'Visa förhandsvisningar',
			hide_teasers: 'Göm förhandsvisningar',
			show_on_map: 'Visa på kartan',
			show_all_routes: 'Visa alla leder',
			show_modal: 'Visa hjälp',
			map_modal_title: 'Välkommen',
			map_modal_text: 'Det finns många vackra leder och för att det ska bli översiktligt presenterar vi dem på tre olika nivåer. Zooma in i kartan för att se fler leder.',
			map_modal_button_text: 'OK!',
			show_search: 'Sök',
			search_modal_title: 'Sök',
			search_modal_text: 'Sök leder och pilgrimscentrum på namn',
			search_modal_search_button_text: 'Sök',
			search_modal_cancel_button_text: 'Ångra',
		},
		month: {
			0: 'Jan',
			1: 'Feb',
			2: 'Mar',
			3: 'Apr',
			4: 'Maj',
			5: 'Jun',
			6: 'Jul',
			7: 'Aug',
			8: 'Sep',
			9: 'Okt',
			10: 'Nov',
			11: 'Dec',
		}
	},
	de: {
		string: {
			track: 'Track',
			tracks: 'Tracks',
			track_difficulty: 'Difficulty',
			track_signage: 'Signage',
			event: 'Event',
			events: 'Events',
			pilgrim_centre: 'Pilgrim Centre',
			pilgrim_centres: 'Pilgrim Centres',
			filter: 'Filter',
			filters: 'Filters',
			filters_selected: 'Filters selected',
			layers: 'Layers',
			show_teasers: 'Show previews',
			hide_teasers: 'Hide previews',
			show_on_map: 'Show on map',
			show_all_routes: 'Show all routes',
			show_modal: 'Show help',
			map_modal_title: 'Modal title',
			map_modal_text: 'A falsis, demolitione germanus resistentia? Verpas manducare in fatalis cubiculum! Cum abactor nocere, omnes valebates captis teres, peritus lumenes. Equiso barbatus sectam est.',
			map_modal_button_text: 'OK, got it!',
			show_search: 'Search',
			search_modal_title: 'Search',
			search_modal_text: 'Find routes and pilgrim centres by name.',
			search_modal_search_button_text: 'Search',
			search_modal_cancel_button_text: 'Cancel',
		},
		month: {
			0: 'Jan',
			1: 'Feb',
			2: 'Mar',
			3: 'Apr',
			4: 'Maj',
			5: 'Jun',
			6: 'Jul',
			7: 'Aug',
			8: 'Sep',
			9: 'Okt',
			10: 'Nov',
			11: 'Dec',
		}
	},
}
