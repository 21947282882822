<template>
	<div :class="classes">
		<div v-html="label + ':'" class="w-full xl:w-1/6 font-bold text-left xl:text-right text-sm"></div>
		<ul class="w-full grid grid-rows-3 grid-cols-1 lg:grid-cols-3 lg:grid-rows-1 justify-items-stretch items-center gap-2">
			<filter-option
				v-for="option in options"
				v-on:clicked="onOptionClicked"
				v-model="option.enabled"
				v-bind:filterOption="option"
				v-bind:key="option.id"
			></filter-option>
		</ul>
	</div>
</template>

<script>

import FilterOption from "./filter_option";

export default {
	name: "OptionsFilter",
	components: {FilterOption},
	props: {
		options: Array,
		label: String,
		className: String,
	},
	computed: {
		classes: function () {
			let classes = [
				'w-full',
				'flex',
				'flex-col',
				'xl:flex-row',
				'gap-3',
				'items-center'
			];
			classes.push('filter-' + this.className);

			return classes;
		}
	},
	methods: {
		onOptionClicked(option) {
			this.$parent.$parent.$parent.updateTrackVisibility(option); // LOL
		},
	}
}
</script>

<style scoped>

</style>
