<template>
	<article class="min-w-416 bg-pax_white mb-5" v-if="marker.visible">
		<img v-if="marker.image" :src="marker.image" :alt="marker.title" class="mb-0">
		<div class="prose p-3">
			<a v-if="marker.website" :href="marker.website" target="_blank">
				<h3 v-html="marker.title" class="m-0 text-pax_gray-800"></h3>
			</a>
			<h3 v-if="!marker.website" v-html="marker.title" class="m-0 text-pax_gray-800"></h3>
			<div class="text-pax_blue-400" v-if="marker.city">
				<icon-label :icon="'before:bg-location-open-blue'" :label="marker.city"></icon-label>
			</div>
			<p v-if="marker.description" v-html="marker.description" class="m-0 text-sm text-pax_gray-450 mb-2"></p>
			<button v-on:click="setFocus" :class="buttonClasses">
				<icon-label :icon="'before:bg-location-open-blue'" :label="$t('string.show_on_map')"></icon-label>
			</button>
		</div>
	</article>
</template>

<script>
import IconLabel from './icon-label';

export default {
	name: "MarkerTeaser",
	components: { IconLabel },
	props: {
		marker: Object,
	},
	computed: {
		buttonClasses:function() {
			return [
				'cursor-pointer',
				'border',
				'border-pax_trans_gray-500',
				'p-3',
				'py-2',
				'text-pax_blue-400',
			];
		}
	},
	methods: {
		setFocus () {
			this.$emit( 'focusMarker', this.marker.id );
		}
	}
}
</script>

<style scoped>

</style>
