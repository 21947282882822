<template>
	<article class="flex font-sans">
		<img class="w-36 h-36 shrink-0" v-if="marker.thumbnail" :src="marker.thumbnail" :alt="marker.title">
		<div class="prose p-3 w-full min-h-36">
			<a v-if="marker.website" :href="marker.website" target="_blank" class="decoration-pax_gray-800">
				<h3 v-html="marker.title" class="m-0 text-pax_gray-800"></h3>
			</a>
			<h3 v-if="!marker.website" v-html="marker.title" class="m-0 text-pax_gray-800"></h3>
			<div class="text-pax_blue-400" v-if="marker.city">
				<icon-label :icon="'before:bg-location-open-blue'" :label="marker.city"></icon-label>
			</div>
			<div v-if="marker.description" v-html="marker.description" class="m-0 text-sm leading-5 text-pax_gray-450"></div>
		</div>
	</article>
</template>

<script>
import IconLabel from './icon-label';

export default {
	name: "MarkerPopup",
	components: { IconLabel },
	props: {
		marker: Object,
	}
}
</script>

<style scoped>
.leaflet-popup-content > p {
	margin: 0;
}
</style>
