<template>
	<article class="flex font-sans">
		<div class="w-36 h-36 shrink-0 relative">
			<event-date
				:start-date="event.startDate"
				:end-date="event.endDate"
			></event-date>
			<img class="object-contain" v-if="event.thumbnailUrl" :src="event.thumbnailUrl" :alt="event.title">
		</div>
		<div class="prose p-3 w-full min-h-36">
			<a v-if="event.externalLink" :href="event.externalLink" target="_blank" class="decoration-pax_gray-800">
				<h3 v-html="shorten( event.title, 80 )" class="m-0 text-pax_gray-800"></h3>
			</a>
			<h3 v-if="!event.externalLink" v-html="shorten( event.title, 80 )" class="m-0 text-pax_gray-800"></h3>
			<div v-if="event.description" v-html="shorten( event.description, 120 )" class="m-0 not-prose p:m-0 text-sm leading-5 text-pax_gray-450"></div>
			<div class="text-pax_blue-400" v-if="event.venueName">
				<icon-label :icon="'before:bg-location-open-blue'" :label="shorten( event.venueName, 100 )"></icon-label>
			</div>
		</div>
	</article>
</template>

<script>
import IconLabel from './icon-label';
import EventDate from "./event_date";

export default {
	name: "MarkerPopup",
	components: { IconLabel, EventDate },
	props: {
		event: Object,
	},
	computed: {},
	methods: {
		shorten ( string, length ) {
			string = string.trim();
			if (string.length < length) {
				return string;
			}

			return string.substring(0, length) + '...';
		}
	}
}
</script>

<style scoped>

</style>
