<template>
	<button :class="classes" v-on:click="trigger">
		<img :src="icon" :alt="label">
		{{ label }}
	</button>
</template>

<script>
export default {
	name: "feature_toggle",
	props: {
		show: Boolean,
		label: String,
		icon: String,
		message: String,
	},
	computed: {
		classes: function () {
			let classes = [
				'flex',
				'flex-col',
				'justify-center',
				'items-center',
				'h-25',
				'w-25',
				'border',
			];
			if (this.show) {
				classes.push(
					[
						'bg-pax_blue-400',
						'text-pax_white',
						'border-5',
						'border-pax_white',
					]
				);
			} else {
				classes.push(
					[
						'text-pax_gray-500',
						'bg-pax_white',
						'border-1',
						'border-pax_trans_blue-500',
					],
				);
			}

			return classes;
		}
	},
	methods: {
		trigger: function () {
			this.$emit(this.message);
		}
	}
}
</script>

<style scoped>
</style>
