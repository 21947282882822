<template>
	<section :class="modalClasses">
		<div class="bg-pax_blue-500 text-pax_white p-10 flex flex-col justify-center prose">
			<h2 class="text-pax_white text-2xl">{{ $t('string.map_modal_title') }}</h2>
			<p class="pb-4">
				{{ $t('string.map_modal_text') }}
			</p>
			<button class="bg-pax_blue-500 text-pax_white border border-pax_white p-2" v-on:click="closeModal">
				{{ $t('string.map_modal_button_text') }}
			</button>
		</div>
	</section>
</template>

<script>
export default {
	name: "modal",
	computed: {
		modalClasses: function() {
			return [
				'absolute',
				'top-0',
				'left-0',
				'z-[10000]',
				'h-full',
				'w-full',
				'flex',
				'justify-center',
				'items-center',
				'bg-pax_trans_gray-500',
			];
		}
	},
	methods: {
		closeModal () {
			this.$emit( 'closeModal' );
		}
	},
}
</script>

<style scoped>

</style>
