<template>
	<li class="filter-option">
		<button id="filterOption.id" :class="classes" v-html="filterOption.name" v-on:click="toggleEnabled"></button>
	</li>
</template>

<script>

export default {
	name: "FilterOption",
	props: {
		filterOption: Object,
	},
	methods: {
		toggleEnabled () {
			this.$emit( 'clicked', this.filterOption );
		}
	},
	computed: {
		classes: function () {
			let classes = ['w-full', 'border', 'rounded-2xl', 'py-1', 'px-3', 'text-sm', 'inline-block'];

			if (this.filterOption.enabled) {
				classes = classes.concat( ['bg-pax_yellow', 'border-pax_yellow', 'text-pax_gray-500'] );
			} else {
				classes = classes.concat( ['bg-pax_white', 'border-pax_trans_gray-100', 'text-pax_gray-500'] );
			}

			return classes;
		}
	}
}
</script>

<style scoped>
</style>
