import Vue from "vue";
import VueI18n from "vue-i18n";
import Map from "./components/map.vue";
import messages from "./_translations";
import { VueWindowSizePlugin } from "vue-window-size/plugin";
import VueCookies from 'vue-cookies'

Vue.use(VueI18n);
Vue.use(VueWindowSizePlugin);

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expires: '30d'});

const i18n = new VueI18n({
	locale: window.paxwalk_map.current_lang, // set to polylang current language.
	messages: messages,
});

Vue.filter('shorten', function (string, length) {
	string = string.trim();
	if (string.length < length) {
		return string;
	}

	return string.substring(0, length) + '...';
})

const Mapp = new Vue(
	{
		i18n,
		el: '#paxwalk_map',
		render: h => h( Map ),
	}
);

