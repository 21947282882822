<template>
	<article class="min-w-416 bg-pax_white mb-4">
		<div class="w-full relative">
			<event-date
				:start-date="event.startDate"
				:end-date="event.endDate"
			></event-date>
			<img class="w-full object-cover" v-if="event.imageUrl" :src="event.imageUrl" :alt="event.title">
		</div>
		<div class="p-3 w-full min-h-36">
			<a v-if="event.externalLink" :href="event.externalLink" target="_blank">
				<h3 v-html="shorten( event.title, 80 )" class="mt-0 text-pax_black underline"></h3>
			</a>
			<h3 v-if="!event.externalLink" v-html="shorten( event.title, 80 )" class="text-pax_black"></h3>
			<div class="text-pax_blue-400" v-if="event.venueName">
				<icon-label :icon="'before:bg-location-open-blue'" :label="shorten( event.venueName, 40 )"></icon-label>
			</div>
			<div v-if="event.description" v-html="shorten( event.description, 100 )" class="text-sm text-pax_gray-400"></div>
			<button v-if="event.coordinates.lat" v-on:click="setFocus" :class="buttonClasses">
				<icon-label :icon="'before:bg-location-open-blue'" :label="$t('string.show_on_map')"></icon-label>
			</button>
		</div>
	</article>
</template>

<script>
import IconLabel from './icon-label';
import EventDate from './event_date';

export default {
	name: "EventTeaser",
	components: { IconLabel, EventDate },
	props: {
		event: Object,
	},
	computed: {
		buttonClasses:function() {
			return [
				'cursor-pointer',
				'border',
				'border-pax_trans_gray-500',
				'p-3',
				'py-2',
				'mt-2',
				'text-pax_blue-400',
			];
		}
	},
	methods: {
		setFocus () {
			this.$emit( 'focusEvent', this.event.id );
		},
		shorten ( string, length ) {
			string = string.trim();
			if (string.length < length) {
				return string;
			}

			return string.substring(0, length) + '...';
		}
	}
}
</script>

<style scoped>

</style>
