<template>
	<section :class="modalClasses">
		<div class="bg-pax_blue-500 text-pax_white p-10 flex flex-col justify-center prose">
			<h2 class="text-pax_white text-2xl">{{ $t('string.search_modal_title') }}</h2>
			<p class="pb-4">
				{{ $t('string.search_modal_text') }}
			</p>
			<input type="text" v-model="searchString" @keyup.enter="setSearchString" @keyup.esc="closeSearch" placeholder="Search" class="text-pax_black"/>
			<footer class="flex gap-2 mt-2">
				<button class="flex-1 bg-pax_blue-500 text-pax_white border border-pax_white p-2" v-on:click="setSearchString">
					{{ $t('string.search_modal_search_button_text') }}
				</button>
				<button class="flex-1 bg-pax_blue-500 text-pax_white border border-pax_white p-2" v-on:click="closeSearch">
					{{ $t('string.search_modal_cancel_button_text') }}
				</button>
			</footer>
		</div>
	</section>
</template>

<script>
export default {
	name: "search",
	props: {
		searchStringCurrent: String,
	},
	data() {
		return {
			searchString: "",
		}
	},
	created() {
		this.searchString = this.searchStringCurrent;
	},
	computed: {
		modalClasses: function() {
			return [
				'absolute',
				'top-0',
				'left-0',
				'z-[10000]',
				'h-full',
				'w-full',
				'flex',
				'justify-center',
				'items-center',
				'bg-pax_trans_gray-500',
			];
		}
	},
	methods: {
		closeSearch () {
			this.$emit( 'setSearchString', '' );
			this.$emit( 'closeSearch' );
		},
		setSearchString () {
			this.$emit( 'setSearchString', this.searchString );
			this.$emit( 'closeSearch' );
		}
	},
}
</script>

<style scoped>

</style>
