<template>
	<span :class="classes" v-if="term.name">{{ term.name }}</span>
</template>

<script>
export default {
	name: "TermLabel",
	props: {
		term: Object,
	},
	computed: {
		classes: function () {
			let classes = ['border', 'rounded-full', 'py-1', 'pb-2', 'px-3', 'text-sm'];

			if (typeof this.term.level !== 'undefined') {

				switch (this.term.level) {
					case 'hard':
					case 'moderate':
					case 'easy':
						classes = classes.concat(['bg-pax_yellow', 'border-pax_yellow', 'text-pax_black']);
						break;
				}
			} else {
				classes = classes.concat(['border-pax_gray-400']);
			}

			return classes;
		}
	},
}
</script>

<style scoped>

</style>
