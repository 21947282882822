<template>
	<div class="absolute min-w-16 bg-pax_white p-1 px-2 prose">
		<div v-if="startDate" :class="dateClasses">
			<span v-if="startDate">{{ startDate.getDate() }}</span><span v-if="endDate && endDate.getDate() !== startDate.getDate()">-{{ endDate.getDate() }}</span>
		</div>
		<div v-if="startDate" :class="monthClasses">
			<span v-if="startDate">{{ $t('month.' + startDate.getMonth()) }}</span><span v-if="startDate.getMonth() !== endDate.getMonth()">-{{ $t('month.' + endDate.getMonth())  }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "EventDate",
	props: {
		startDate: Date,
		endDate: Date,
	},
	computed: {
		dateClasses: function() {
			return [ 'text-center', 'text-pax_red', 'text-sm' ];
		},
		monthClasses: function() {
			let classes = [ 'text-center', 'uppercase', 'border-t', 'border-pax_black' ];
			if (this.startDate.getMonth() !== this.endDate.getMonth()) {
				classes.push('text-xs');
				classes.push('py-1');
			}

			return classes;
		},
	}
}
</script>

<style scoped>

</style>
