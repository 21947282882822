<template>
	<article class="flex font-sans text">
		<img class="w-36 h-36 shrink-0" v-if="track.thumbnail" :src="track.thumbnail" :alt="track.title">
		<div class="prose p-3 w-full min-h-36">
			<a v-if="track.website" :href="track.website" target="_blank" class="decoration-pax_gray-800">
				<h3 v-html="track.title" class="m-0 text-pax_gray-800"></h3>
			</a>
			<h3 v-if="!track.website" v-html="track.title" class="m-0 text-pax_gray-800"></h3>
			<ul class="p-0 m-0 mb-2">
				<li v-if="isTerm(track.difficulty)" class="p-0 inline-block">
					<term-label :term="difficulty"></term-label>
				</li>
				<li v-if="isTerm(track.visibility)" class="inline-block">
					<term-label :term="track.visibility"></term-label>
				</li>
				<li v-if="track.length" class="inline-block">
					<icon-label :icon="'before:bg-arrows-left-right-dark'" :label="track.length"></icon-label>
				</li>								</ul>
			<p v-if="track.description" v-html="track.description" class="m-0 text-sm leading-5 text-pax_gray-450"></p>
		</div>
	</article>
</template>

<script>
import TermLabel from "./term-label";
import IconLabel from "./icon-label";

export default {
	name: "TrackPopup",
	components: { TermLabel, IconLabel },
	props: {
		track: Object,
		difficulties: Array,
	},
	computed: {
		difficulty: function () {
			let difficulty = this.track.difficulty;

			this.difficulties.forEach( (term, index ) => {
				if ( term.id === this.track.difficulty.id ) {
					difficulty = term;
				}
			} );

			return difficulty;
		}
	},
	methods: {
		isTerm ( term ) {
			return typeof term === 'object';
		}
	}
}
</script>

<style scoped>
.leaflet-popup-content p {
	margin: 0;
}
</style>
