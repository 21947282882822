<template>
	<span :class="classes">{{ label }}</span>
</template>

<script>
export default {
	name: "IconLabel",
	props: {
		icon: String,
		label: String,
		position: String,
	},
	computed: {
		classes: function () {
			let classes = ['text-sm'];

			if (this.icon) {
				if (this.position === 'after') {
					classes = classes.concat(
						[
							'after:relative',
							'after:inline-block',
							'after:top-0.5',
							'after:bg-center',
							'after:bg-contain',
							'after:bg-no-repeat',
							'after:w-4',
							'after:h-4',
							'after:ml-2',
							this.icon
						]
					);
				} else {
					classes = classes.concat(
						[
							'before:relative',
							'before:inline-block',
							'before:top-0.5',
							'before:bg-center',
							'before:bg-contain',
							'before:bg-no-repeat',
							'before:w-4',
							'before:h-4',
							'before:mr-2',
							this.icon
						]
					);
				}
			}

			return classes;
		},
	}
}
</script>

<style scoped>

</style>
